import i18next from 'i18next';
import { FuseNavItemType } from '@fuse/core/FuseNavigation/types/FuseNavItemType';
import bg from './navigation-i18n/bg';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('bg', 'navigation', bg);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */

const userRoleRaw = localStorage.getItem('role');
const userRoles = userRoleRaw ? JSON.parse(userRoleRaw) : [];

// Check if userRoles is an array and includes 'admin', or if it's a string equals to 'admin'
const isAdmin = Array.isArray(userRoles) ? userRoles.includes('admin') : userRoles === 'admin';

const navigationConfig: FuseNavItemType[] = [
	{
		id: 'dashboards',
		title: 'Dashboards',
		// subtitle: 'Unique dashboard designs',
		type: 'group',
		icon: 'heroicons-outline:home',
		translate: 'DASHBOARDS',
		children: [
			{
				id: 'dashboards.project',
				title: 'Home',
				type: 'item',
				icon: 'heroicons-outline:home',
				translate: 'HOME',
				url: '/home',
				end: true
			},
			{
				id: 'references',
				title: 'Reference',
				type: 'collapse',
				icon: 'heroicons-outline:chart-bar',
				translate: 'CALCS',
				children: [
					{
						id: 'working-today-reference',
						title: 'Working Today',
						type: 'item',
						translate: 'WORKINGTODAY',
						url: 'reference/working-today',
						icon: 'heroicons-outline:users',
						end: true
					},
					{
						id: 'events-reference',
						title: 'Events',
						type: 'item',
						translate: 'EVENTS',
						url: 'reference/events',
						icon: 'heroicons-outline:calendar',
						end: true
					},
					{
						id: 'work-hours-reference',
						title: 'Work Hours',
						type: 'item',
						translate: 'WORKHOURS',
						url: 'reference/working-hours',
						icon: 'heroicons-outline:clock',
						end: true
					},
					{
						id: 'discrepancies-reference',
						title: 'Discrepancies',
						type: 'item',
						translate: 'DISCREPANCIES',
						url: 'reference/discrepancies',
						icon: 'material-outline:new_releases',
						end: true
					},
					// {
					// 	id: 'form76-reference',
					// 	title: 'Form 76',
					// 	type: 'item',
					// 	translate: 'FORM76',
					// 	url: 'reference/form76',
					// 	icon: 'material-outline:note_add',
					// 	end: true
					// },
					{
						id: 'changes-reference',
						// disabled: !isAdmin,
						title: 'Changes',
						type: 'item',
						translate: 'CHANGES',
						url: 'reference/changes',
						icon: 'material-outline:edit_calendar',
						end: true
					},
					// {
					// 	id: 'raw-events-reference',
					// 	// disabled: !isAdmin,
					// 	title: 'Raw System Events',
					// 	type: 'item',
					// 	translate: 'RAWEVENTS',
					// 	url: 'reference/raw-system-events',
					// 	icon: 'material-outline:track_changes',
					// 	end: true
					// }
				]
			},
			{
				id: 'employees',
				title: 'Employees',
				type: 'item',
				icon: 'heroicons-outline:users',
				translate: 'EMPLOYEES',
				url: '/employees/list',
				end: true
			},
			{
				id: 'employees-off',
				title: 'Fired Employees',
				type: 'item',
				icon: 'material-outline:block',
				translate: 'EMPLOYEESOFF',
				url: '/employees/fired/list',
				end: true
			},
			{
				id: 'devices',
				title: 'Devices',
				type: 'item',
				icon: 'material-outline:device_hub',
				translate: 'DEVICES',
				url: '/devices/list',
				end: true
			},
			{
				id: 'Allocation',
				title: 'Allocation',
				type: 'item',
				icon: 'heroicons-outline:finger-print',
				translate: 'ALLOCATION',
				url: '/allocation',
				end: true
			},
		]
	},
	// {
	// 	id: 'divider-1',
	// 	type: 'divider'
	// },
];

export default navigationConfig;
