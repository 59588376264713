import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import _ from '@lodash';
import TextField from '@mui/material/TextField';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

type formValuesType = { name: string; email: string; subject: string; message: string };

const defaultValues = { name: '', email: '', subject: '', message: '' };

const schema = z.object({
	name: z.string().nonempty('You must enter a name'),
	subject: z.string().nonempty('You must enter a subject'),
	message: z.string().nonempty('You must enter a message'),
	email: z.string().email('You must enter a valid email').nonempty('You must enter an email')
});

/**
 * The help center support.
 */
function HelpCenterSupport() {
	const { control, handleSubmit, watch, formState } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: zodResolver(schema)
	});
	const { isValid, dirtyFields, errors } = formState;

	const form = watch();

	function onSubmit(data: formValuesType) {
		// eslint-disable-next-line no-console
		console.log(data);
	}

	if (_.isEmpty(form)) {
		return null;
	}

	return (
		<div className="flex flex-col items-center p-24 sm:p-40 container">
			<div className="flex flex-col w-full max-w-4xl">
				<div className="sm:mt-32">
					<Button
						component={Link}
						to="/apps/help-center"
						color="secondary"
						startIcon={<FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>}
					>
						Back to Help Center
					</Button>
				</div>
				<div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
					Contact support
				</div>

				<Paper className="mt-32 sm:mt-48 p-24 pb-28 sm:p-40 sm:pb-28 rounded-2xl">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="px-0 sm:px-24"
					>
						<div className="mb-24">
							<Typography className="text-2xl font-bold tracking-tight">Submit your request</Typography>
							<Typography color="text.secondary">
								Your request will be processed and our support staff will get back to you in 24 hours.
							</Typography>
						</div>
						<div className="space-y-32">
							<Controller
								control={control}
								name="name"
								render={({ field }) => (
									<TextField
										className="w-full"
										{...field}
										label="Name"
										placeholder="Name"
										id="name"
										error={!!errors.name}
										helperText={errors?.name?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>

							<Controller
								control={control}
								name="email"
								render={({ field }) => (
									<TextField
										{...field}
										className="mt-16 w-full"
										label="Email"
										placeholder="Email"
										variant="outlined"
										fullWidth
										error={!!errors.email}
										helperText={errors?.email?.message}
										required
									/>
								)}
							/>

							<Controller
								control={control}
								name="subject"
								render={({ field }) => (
									<TextField
										{...field}
										className="mt-16 w-full"
										label="Subject"
										placeholder="Subject"
										variant="outlined"
										fullWidth
										error={!!errors.subject}
										helperText={errors?.subject?.message}
										required
									/>
								)}
							/>

							<Controller
								name="message"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										label="Message"
										className="mt-16 w-full"
										margin="normal"
										multiline
										minRows={4}
										variant="outlined"
										error={!!errors.message}
										helperText={errors?.message?.message}
										required
									/>
								)}
							/>
						</div>
						<div className="flex items-center justify-end mt-32">
							<Button className="mx-8">Cancel</Button>
							<Button
								className="mx-8"
								variant="contained"
								color="secondary"
								disabled={_.isEmpty(dirtyFields) || !isValid}
								type="submit"
							>
								Save
							</Button>
						</div>
					</form>
				</Paper>
			</div>
		</div>
	);
}

export default HelpCenterSupport;
