import { lazy } from 'react';
import { Protect } from "@clerk/clerk-react";
import authRoles from "../../../auth/authRoles";


const ProjectDashboardApp = lazy(() => import('./ProjectDashboardApp'));

/**
 * The ProjectDashboardApp configuration.
 */
const ProjectDashboardAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'dashboards/project',
			element: (
				<Protect
					permission="org:crud:everything"
					fallback={<p>Only a member of the Admin department can access this content.</p>}
				>
					<ProjectDashboardApp />
				</Protect>
			),
		}
	]
};

export default ProjectDashboardAppConfig;
