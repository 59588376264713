import { FuseRouteConfigsType } from '@fuse/utils/FuseUtils';
import HomeAppConfig from './project/HomeAppConfig';

/**
 * Dashboards
 */
const homeConfigs: FuseRouteConfigsType = [
	HomeAppConfig
];

export default homeConfigs;
