const locale = {
	HOME: 'Home',
	APPLICATIONS: 'Applications',
	DASHBOARDS: 'Menu',
	CALCS: 'Reference',
	WORKINGTODAY: 'Working Today',
	EVENTS: 'Events',
	WORKHOURS: 'Working Hours',
	DISCREPANCIES: 'Discrepancies',
	FORM76: 'Form 76',
	CHANGES: 'Changes',
	RAWEVENTS: 'Raw System Events',
	EMPLOYEES: 'Employees',
	EMPLOYEESOFF: 'Fired Employees',
	DEVICES: 'Devices',
	ALLOCATION: 'Allocation',
	CALENDAR: 'Calendar',
	ECOMMERCE: 'E-Commerce',
	ACADEMY: 'Academy',
	MAIL: 'Mail',
	TASKS: 'Tasks',
	FILE_MANAGER: 'File Manager',
	CONTACTS: 'Contacts',
	MESSENGER: 'Messenger',
	SCRUMBOARD: 'Scrumboard',
	NOTES: 'Notes'
};

export default locale;
