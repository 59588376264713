import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import App from './app/App';
import * as Sentry from "@sentry/react";

// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

/**
 * The root element of the application.
 */
const container = document.getElementById('root');

if (!container) {
	throw new Error('Failed to find the root element');
}

// Import your publishable key
const PUBLISHABLE_KEY = "pk_test_Z3Jvd24teWFrLTk4LmNsZXJrLmFjY291bnRzLmRldiQ"

if (!PUBLISHABLE_KEY) {
	throw new Error("Missing Publishable Key")
}

// Sentry
Sentry.init({
	dsn: "https://90038c75e84a418fe2bc71d2e181f7a0@o4506783720144896.ingest.sentry.io/4506783721521152",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/billa.biodit\.bg\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * The root component of the application.
 */
const root = createRoot(container);

root.render( <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
	<App />
</ClerkProvider>);

// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
