/**
 * The authRoles object defines the authorization roles for the Fuse application.
 */
const authRoles = {
	/**
	 * The godfather role grants access to users with the 'godfather' role.
	 */
	godfather: ['godfather'],

	super: ['super'],

	/**
	 * The admin role grants access to users with the 'admin' role.
	 */
	admin: ['admin'],

	/**
	 * The shop_manager role grants access to users with the 'admin' or 'staff' role.
	 */
	shop_manager: ['admin', 'shop_manager'],

	/**
	 * The user role grants access to users with the 'admin', 'staff', or 'user' role.
	 */
	user: ['admin', 'staff', 'user'],

	/**
	 * The onlyGuest role grants access to unauthenticated users.
	 */
	onlyGuest: []
};

export default authRoles;
