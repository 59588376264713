import { lazy } from 'react';
import { Protect } from "@clerk/clerk-react";
import authRoles from "../../../auth/authRoles";


const HomeApp = lazy(() => import('./HomeApp'));

/**
 * The HomeApp configuration.
 */
const HomeAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: null,
	routes: [
		{
			path: 'home',
			element: (
				<HomeApp />
			),
		}
	]
};

export default HomeAppConfig;
