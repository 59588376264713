const locale = {
	HOME: 'Начало',
	APPLICATIONS: 'Приложения',
	DASHBOARDS: 'Меню',
	CALCS: 'Справки',
	WORKINGTODAY: 'На Работа Днес',
	EVENTS: 'Събития',
	WORKHOURS: 'Справка Часове',
	DISCREPANCIES: 'Разминавания',
	FORM76: 'Форма 76',
	CHANGES: 'Промени',
	RAWEVENTS: 'Системни Събития',
	EMPLOYEES: 'Служители',
	EMPLOYEESOFF: 'Изключени Служители',
	DEVICES: 'Устройства',
	ALLOCATION: 'Разпределяне',
	CALENDAR: 'Календар',
	ECOMMERCE: 'E-Commerce',
	ACADEMY: 'Академия',
	MAIL: 'Имейл',
	TASKS: 'Задачи',
	FILE_MANAGER: 'File Manager',
	CONTACTS: 'Потрбители',
	MESSENGER: 'Messenger',
	SCRUMBOARD: 'Scrumboard',
	NOTES: 'Notes'
};

export default locale;
