import { lazy } from 'react';

const ChangesApp = lazy(() => import('./ChangesApp'));

/**
 * The tasks app config.
 */
const ChangesAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: null,
	routes: [
		{
			path: 'reference/changes',
			element: <ChangesApp />,
			// children: [
			// 	{
			// 		path: ':id',
			// 		element: <TaskForm />
			// 	},
			// 	{
			// 		path: ':id/:type',
			// 		element: <TaskForm />
			// 	}
			// ]
		}
	]
};

export default ChangesAppConfig;
