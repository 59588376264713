import { FuseRouteConfigsType } from '@fuse/utils/FuseUtils';
import AcademyAppConfig from './academy/AcademyAppConfig';
import CalendarAppConfig from './calendar/CalendarAppConfig';
import MessengerAppConfig from './messenger/MessengerAppConfig';
import ContactsAppConfig from './contacts/ContactsAppConfig';
import ECommerceAppConfig from './e-commerce/ECommerceAppConfig';
import FileManagerAppConfig from './file-manager/FileManagerAppConfig';
import HelpCenterAppConfig from './help-center/HelpCenterAppConfig';
import MailboxAppConfig from './mailbox/MailboxAppConfig';
import NotesAppConfig from './notes/NotesAppConfig';
import ProfileAppConfig from './profile/profileAppConfig';
import ScrumboardAppConfig from './scrumboard/ScrumboardAppConfig';
import TasksAppConfig from './tasks/TasksAppConfig';
import NotificationsAppConfig from './notifications/NotificationsAppConfig';
// BIODIT
import EventAppConfig from './references/events/EventsAppConfig';
import WorkingTodayAppConfig from './references/working-today/WorkingTodayAppConfig';
import WorkingHoursAppConfig from './references/working-hours/WorkingHoursAppConfig';
import DiscrepanciesAppConfig from './references/discrepancies/DiscrepanciesAppConfig';
import Form76AppConfig from "./references/form76/Form76AppConfig";
import ChangesAppConfig from "./references/changes/ChangesAppConfig"
import EmployeesAppConfig from "./employees/EmployeesAppConfig";
import EmployeesOffAppConfig from "./employees-off/EmployeesOffAppConfig";
import DevicesAppConfig from './devices/DevicesAppConfig';
import AccountAppConfig from './account/AccountAppConfig';
import AllocationAppConfig from './allocation/AllocationAppConfig'

/**
 * The list of application configurations.
 */
const appsConfigs: FuseRouteConfigsType = [
	EventAppConfig,
	WorkingTodayAppConfig,
	EmployeesAppConfig,
	WorkingHoursAppConfig,
	DiscrepanciesAppConfig,
	Form76AppConfig,
	ChangesAppConfig,
	EmployeesAppConfig,
	EmployeesOffAppConfig,
	DevicesAppConfig,
	AccountAppConfig,
	AllocationAppConfig,
	AcademyAppConfig,
	CalendarAppConfig,
	MessengerAppConfig,
	ContactsAppConfig,
	ECommerceAppConfig,
	FileManagerAppConfig,
	HelpCenterAppConfig,
	MailboxAppConfig,
	NotesAppConfig,
	ProfileAppConfig,
	ScrumboardAppConfig,
	TasksAppConfig,
	NotificationsAppConfig,
];

export default appsConfigs;
