import { lazy } from 'react';

const WorkingHoursApp = lazy(() => import('./WorkingHoursApp'));

/**
 * The tasks app config.
 */
const WorkingHoursAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: null,
	routes: [
		{
			path: 'reference/working-hours',
			element: <WorkingHoursApp />,
			// children: [
			// 	{
			// 		path: ':id',
			// 		element: <TaskForm />
			// 	},
			// 	{
			// 		path: ':id/:type',
			// 		element: <TaskForm />
			// 	}
			// ]
		}
	]
};

export default WorkingHoursAppConfig;
